(function () {
  'use strict';

  angular
    .module('neo.home.referees')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.referees', {
        url: '/referees',
        templateUrl: 'home/referees/referees.tpl.html',
        controller: 'RefereesCtrl',
        controllerAs: 'vm',
        resolve:{
          townHalls: function (Ajuntaments) {
            return Ajuntaments.query().$promise;
          }
        }
      });
  }
}());
